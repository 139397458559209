@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
html {
  scroll-behavior: smooth;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: rgb(235, 246, 255);
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}
.logo {
  height: "50px";
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: rgb(235, 246, 255);
  color: var(--textColor);
}
.live {
  display: flex;
  flex-direction: row;
  padding-left: 0em;
}
.heading {
  font-family: "Poppins";
  font-weight: 400;
  letter-spacing: 0.3mm;
  color: black;
  padding-left: 1rem;
}
nav a {
  margin: 0 2rem;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

nav a:hover {
  color: rgb(131, 141, 154);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    z-index: 10;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}
